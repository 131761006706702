.button {
    padding: 0;
    display: flex;
    align-items: center;
    transition: 0.2s ease all;
    .icon {
        margin-left: 5px;
    }
    &.readMore:hover {
        text-decoration: underline;
        transform: scale(1.02) translateY(2px);
    }
    &.readLess:hover {
        text-decoration: underline;
        transform: scale(1.02) translateY(-2px);
    }
}

// DONT DELETE COMMENTED CODE
// below are animation classes that might be helpful in future

// .readMoreFunctionality {
//     overflow: hidden;
//     animation-duration: 0.8s;
//     animation-fill-mode: forwards;
//     animation-timing-function: ease-out;

//     &.initialState {
//         max-height: 100px;
//     }

//     &.collapsed {
//         animation-name: collapseAnim;
//     }

//     &.expanded {
//         animation-name: expandAnim;
//     }
// }
// @keyframes collapseAnim {
//     from {
//         max-height: 200rem;
//     }

//     to {
//         max-height: 100px;
//     }
// }
// @keyframes expandAnim {
//     from {
//         max-height: 100px;
//     }

//     to {
//         max-height: 200rem;
//     }
// }
