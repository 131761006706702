@import "@Static/css/common";

$color2: getColorValue(3);
$color1: getColorValue(1);

.templateColor {
    color: $color2;
}

.galleryOuterDiv {
    width: 100%;
    overflow-x: hidden;
}

.list_container {
    width: 80%;
}

.conatiner {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px !important;
    position: relative;
}

.award_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.award_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    opacity: 0.6;
}

.section_name {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 32px;
    text-align: center;
}

.brand_logo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
}

.media_card {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 350px;
    height: 397px;
    position: relative;
    cursor: pointer;
    background-color: white;
    color: black;
}

.text_container {
    padding: 18px 24px;
}

.media_card:hover {
    color: $color1;
}

.media_logo {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 4px;
}

.cover_image {
    width: 100%;
    height: 203.84px;
    object-fit: cover;
}

.linear_gradient {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 61.83%,
        rgba(0, 0, 0, 0.6) 100%
    );
}

.media_organistaion_name {
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.02em;
    color: #000000;
}

.schedule_svg {
    fill: #929292 !important;
    font-size: 12px;
}

.info {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #939393;
    position: absolute;
    bottom: 24px;
    left: 24px;
}

.max_2_line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.awardWidth {
    width: 80%;
}

.brandWidth {
    width: 80%;
}

.mediaWidth {
    width: 90%;
}
.dotListClass {
    position: absolute;
    bottom: -70px;
}
@media only screen and (min-width: 769px) {
    .galleryC {
        &:not(.carouselEnabled) {
            display: flex;
            justify-content: center;
            align-content: space-between;
            margin: auto;
        }
    }
    .galleryOuterDiv {
        max-width: min(
            1400px,
            100vw - 100px
        ); // 100px = 2 * 50px (padding of the footer)
    }
}

@media only screen and (max-width: 769px) {
    .media_card {
        margin: auto !important;
    }

    .brand_logo {
        width: 120px;
        height: 120px;
    }

    .brandWidth {
        width: 97%;
    }

    .mediaWidth {
        width: 100%;
    }

    .brand_logo {
        width: 80px;
        height: 80px;
    }
}
