@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$cardBgColor: rgb(var(--template-secondary-color-host-theme, 255, 255, 255));
$cardTextColor: getColorValue(3, (0, 0, 0));

.root {
    box-shadow: #eee 0px 3px 6px;
    border-radius: 5px;
    margin: 10px auto;
    max-width: 300px;
    position: relative;
    background-color: $cardBgColor;
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}

.readMore {
    color: $primaryColor;
    font-size: 14px;
    cursor: pointer;
}
.container {
    width: 100%;
    height: 100%;
}

.image {
    border-radius: 5px 5px 0px 5px;
    width: 100%;
    background: center no-repeat;
    background-size: cover;
}
.cardBody {
    padding: 20px;
}
.iconBox {
    position: absolute;
    top: 5px;
    left: 4px;
}
.cardName {
    color: $cardTextColor;
    font-size: 16px;
    font-weight: bold;
}
.cardtitle {
    color: $cardTextColor;
    font-size: 16px;
    opacity: 60%;
    height: 50px;
    overflow: hidden;
}
.staffDescription {
    color: $cardTextColor;
    font-size: 16px;
    line-height: 20px;
    height: 24px;
    overflow: hidden;
    margin: 20px 0 0;
}
.detailsContainer {
    padding: 10px;
    font-size: 0.8rem;
}

.name {
    font-weight: 600;
}

.heading {
    font-weight: 400;
    height: 1.3rem;
    overflow-y: auto;
    @extend .plainScrollbar;
}

.horizontalBar {
    margin: 5px 0;
}

.description {
    height: 3.2rem;
    overflow-y: auto;
    @extend .plainScrollbar;
}

.readMoreButton {
    color: $primaryColor;
    margin-right: auto;
    font-size: 16px;
    border-radius: 4px;
    text-decoration: underline;
    transition: 0.2s ease all;
    &:hover {
        filter: brightness(1.1);
    }
}
