@import "@Static/css/common";

$color1: getColorValue(1, (0, 0, 0));
$color3: getColorValue(3, (0, 0, 0));

.root {
    padding-top: 1rem;
    padding-bottom: 5rem;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    color: $color3;
    margin: 0px 0px 40px 0px;
    padding: 30px 0px;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: -2rem;
}

.blogPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.blogLink {
    color: $color1;
    margin: 1% 5%;
}
@media only screen and (max-width: 600px) {
    .root {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
    .title {
        font-size: 20px;
    }
}
