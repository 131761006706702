@import "@Static/css/common";

.portfolioCard {
    transition: 0.3s ease all;
    border-radius: 5px;
    max-width: 95vw;
    &:hover {
        transform: scale(1.01) translateY(-4px);
    }
}

.image {
    width: 350px;
    max-width: 100%;
    margin: 20px;
    border-radius: 5px;
    cursor: pointer;
    img {
        width: 100%;
        border-radius: 4px;
        background-color: #eee;
    }
}

.video {
    margin: 20px;
    border-radius: 5px;
    position: relative;
    width: 350px;
    height: 196.875px;
    video {
        border-radius: 5px;
        $aspect-ratio: 16/9;
        $responsive-width: 200px;
        $min-width: 100px;
        $max-width: 300px;

        @include aspectRatio(
            $responsive-width,
            $aspect-ratio,
            $min-width,
            $max-width
        );
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 768px) {
    .image,
    .video {
        width: calc(98vw - 40px);
        max-width: 350px;
    }
    .image img {
        width: 100%;
    }
}
