@import "@Static/css/common";

$textColor: getColorValue(3, (0, 0, 0));

.followerCountTitle {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
}

.item{
    min-width: 66px;
}

.followerCountDescription {
    font-size: 12px;
    opacity: 0.6;
    text-align: center;
}

.followerCountImage {
    width: 30px;
    height: 30px;
}

.templateColor {
    color: $textColor;
}
