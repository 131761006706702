@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (0, 0, 0));

.root {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: $textColor;
}

.container {
    position: relative;
}

.title {
    text-align: center;
    color: $textColor;
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    padding: 0px 30px 40px 30px;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: auto;
    margin-top: 20px;
}

@media only screen and (max-width: 600px) {
    .root {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
    }
}
