@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$textColor: getColorValue(3, (0, 0, 0));

.root {
    padding: 36px 20px 72px;
    max-width: 1240px;
    margin: auto;
}

.container {
    position: relative;
}

.title {
    text-align: center;
    margin: 0px 0px 0px 0px;
    font-size: 46px;
    color: $textColor;
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    padding: 30px;
}

.dotMatrix {
    circle {
        fill: $primaryColor;
    }
    position: absolute;
    top: 80px;
    right: 8px;
}

.dotListClass {
    bottom: -3rem;
    li button {
        background-color: #ccc;
        &:hover {
            background-color: #999;
        }
    }
    li[class*="active"] button {
        background-color: $primaryColor !important;
    }
}
@media only screen and (min-width: 600px) {
    .container {
        &.singleTestimonialContainer > div:not(.title) {
            justify-content: center !important;
        }
    }
}
@media only screen and (max-width: 600px) {
    .root {
        padding: 10px 16px 40px;
    }
    .title {
        font-size: 28px;
        font-weight: bold;
        padding: 30px;
    }
    .dotListClass {
        bottom: -2rem;
    }
}
