@import "@Static/css/common";
$textColor: getColorValue(3, (0, 0, 0));
$borderColor: getColorValue(3, (0, 0, 0), 0.3);

.strengthRoom {
    h1 {
        margin: 70px 0px 0px 0px;
        font-size: 46px;
        color: $textColor;
        text-align: center;
        font-size: 46px;
        line-height: 52px;
        font-weight: bold;
        padding: 30px;
        word-break: break-word;
    }
    .strengthCard {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: stretch;
        padding: 24px 20px;
        width: 100%;
        max-width: 1240px;
        margin: auto;
        position: relative;

        .separator {
            content: "";
            border: 0.5px solid $borderColor;
            background-color: $borderColor;
            margin: 0 16px;
            opacity: 0.3;
            &:last-of-type {
                display: none;
            }
        }

        .strengthContainer {
            flex: 1;
            margin: 30px 0px;
            color: $textColor;
            width: 25%;
            text-align: center;
            &:last-of-type {
                border-right: none;
            }
            .strengthHead {
                font-size: 46px;
                line-height: 52px;
                font-weight: bold;
                padding: 12px 0px;
                word-break: break-word;
            }
            .strengthTxt {
                font-size: 16px;
                word-break: break-word;
            }
        }
    }
}

@media (max-width: 600px) {
    .strengthRoom {
        h1 {
            font-size: 28px;
            font-weight: bold;
            padding: 0px;
            margin-top: 36px;
        }
        .strengthCard {
            flex-direction: column;
            padding: 16px;
            .strengthContainer {
                margin: 16px 0px;
                width: 100%;
                border-right: none;
                .strengthTxt {
                    padding-bottom: 20px;
                }
            }
        }
    }
}
