@import "@Static/css/common";
$cardBgColor: rgb(var(--template-secondary-color-host-theme, 255, 255, 255));
$cardTextColor: getColorValue(3, (0, 0, 0));
$primaryColor: getColorValue(1, (0, 0, 0));

.getInTouchRoot {
    background-image: linear-gradient(90deg, $primaryColor 50%, #000000 50%);
    padding: 40px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .getInTouchBox {
        padding: 20px;
        display: flex;
        max-width: 1240px;

        .getInTouchBoxOne {
            padding-right: 36px;
            .title {
                color: #fff;
                margin: 50px 0px 0px 0px;
                font-size: 46px;
                line-height: 52px;
                font-weight: bold;
                word-break: break-word;
            }
            .description {
                color: #fff;
                font-size: 16px;
                line-height: 32px;
                margin: 20px 0;
            }
            .ctaButton {
                color: $primaryColor;
                border-radius: 5px;
                background-color: #fff;
                line-height: 24px;
                padding: 10px 20px;
            }
            width: 40%;
        }
        .getInTouchBoxTwo {
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            .getInTouchBoxTwoSub {
                width: 100%;
                .getInTouchCard {
                    margin-right: 30px;
                    margin-bottom: 20px;
                    padding: 30px 30px;
                    max-width: 95vw;
                    word-break: break-word;
                    color: $cardTextColor;
                    background-color: $cardBgColor;
                    border-radius: 5px;
                    float: left;
                    width: 40%;

                    h4 {
                        margin: 0px;
                    }
                    p {
                        margin: 0px;
                    }
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .getInTouchRoot {
        background-image: linear-gradient(
            90deg,
            $primaryColor 50%,
            $primaryColor 50%
        );
        padding: 0;
        .getInTouchBox {
            padding: 20px;
            display: flex;
            flex-direction: column;
            .getInTouchBoxOne {
                padding-right: 0!important;
                .title {
                    color: #fff;
                    margin: 12px 0px 0px;
                    font-size: 28px;
                }
                width: 100%;
            }
            .getInTouchBoxTwo {
                width: 100%;
                display: flex;
                margin-top: 20px;
                .getInTouchBoxTwoSub {
                    width: 100%;
                    .getInTouchCard {
                        width: 100%;
                    }
                }
            }
        }
    }
}
